import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { HouseCardProps } from '../../../constants/types';
import numberWithSpaces from '../../../utils/numberWithSpaces';
import Image from "next/image";

const HouseCard = (props: HouseCardProps) => {
  const showings = [...props.showings];
  if (showings.length > 0)
    showings.sort((a, b) => {
      const aDate = new Date(a.TimeStart);
      const bDate = new Date(b.TimeStart);
      if (aDate.getTime() < bDate.getTime()) return -1;
      return 1;
    });
  return (
    <Box
      sx={{
        cursor: 'pointer',
        mx: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        '&:hover': {
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px',
        }
      }}
      className="zoom-in-wrap"
    >
      <Box
        sx={{
          height: { md: '300px', xs: '200px' },
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
          color: '#2F2E41',
        }}
      >
        {props.imagePath && (
          <Image
            src={props.imagePath}
            layout={"fill"}
            className="zoom-in"
            objectFit={"cover"}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {props.bidding && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: 'rgb(156, 197, 172)',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  pt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                Budgivning pågår
              </Typography>
            </Box>
          )}
          {props.status === 5 && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(49, 57, 76)',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  pt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                KOMMANDE
              </Typography>
            </Box>
          )}
          {(props.status === 7 || props.status === 9) && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                color: 'rgb(0, 0, 0)',
                backgroundColor: '#bdcad7',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  pt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                SÅLD
              </Typography>
            </Box>
          )}
          {showings.length > 0 && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: '#F5CEB0',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  mt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                {`Visning ${
                  new Date(showings[0].TimeStart).getDate() +
                  '/' +
                  (new Date(showings[0].TimeStart).getMonth() + 1)
                }${
                  showings[1]
                    ? ' & ' +
                      new Date(showings[1].TimeStart).getDate() +
                      '/' +
                      (new Date(showings[1].TimeStart).getMonth() + 1)
                    : ''
                }`}
              </Typography>
            </Box>
          )}
          {props.isPrivate && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: '#FFF0B4',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  mt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                  UTAN MÄKLARE
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingX: '18px',
          paddingTop: '11px',
          paddingBottom: '15px',
          color: '#2F2E41',
        }}
      >
        <Typography
          component="p"
          className="font-Avenir-Regular"
          sx={{
            fontSize: { xs: '14px', lg: '18px' },
            lineHeight: '25px',
            textTransform: 'uppercase',
            width: 'calc(100%)',
            marginLeft: { xs: 0, sm: 0 },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {props.name}
        </Typography>
        <Typography
          component="p"
          className="font-Avenir-Bold"
          sx={{
            fontSize: { xs: '20px', lg: '30px' },
            textTransform: 'uppercase',
            marginTop: { xs: '3px', lg: '15px' },
            width: 'calc(100%)',
            marginLeft: { xs: '0%', sm: 0 },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {props.address}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: { xs: '10px', lg: '30px' },
          }}
        >
          {props.roomCount !== null && (
            <Typography
              component="p"
              className="font-Avenir-Regular"
              sx={{ fontSize: { lg: '20px', xs: '14px' }, lineHeight: '25px' }}
            >
              {`${props.roomCount} rum`}
            </Typography>
          )}
          <Typography
            component="p"
            className="font-Avenir-Regular"
            sx={{
              fontSize: { lg: '20px', xs: '14px' },
              lineHeight: '25px',
              position: 'relative',
              mr: '5px',
            }}
          >
            {`${props.m2} m`}
            <sup style={{ position: 'absolute', top: '-3px' }}>2</sup>
          </Typography>
          {props.price > 0 && (
            <Typography
              component="p"
              className="font-Avenir-Bold"
              sx={{
                fontWeight: 900,
                fontSize: { xs: '14px', lg: '20px' },
              }}
            >
              {[2, 5].includes(props.status) &&
                `${numberWithSpaces(props.price)} KR`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HouseCard;
