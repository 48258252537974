import React from 'react';
import { Button as MUIButton, Typography, Box } from '@mui/material';
import {
  Modal,
  Fade,
  Backdrop,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from '../../../components/molecules/ContactForm';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const ContactButton = (props) => {
  const { data, entries } = props;
  const [title, setTitle] = React.useState('Kontakt');
  const [style, setStyle] = React.useState('');

  const colors: string[] = style?.split('-');
  let contentColor = !colors ? 'yellow' : colors[1] ?? 'yellow';
  if (contentColor === 'yellow') contentColor = '#FBEBAA';
  if (contentColor === 'blue') contentColor = '#31394C';
  contentColor = contentColor === 'yellow' ? '#FBEBAA' : contentColor;
  let borderColor = !colors ? 'yellow' : colors[2];
  if (borderColor === 'yellow') borderColor = '#FBEBAA';
  if (borderColor === 'blue') borderColor = '#31394C';
  const textColor = !colors ? 'black' : colors[0];

  let buttonColor:
    | 'success'
    | 'warning'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error' = 'success';
  if (contentColor === '#FBEBAA') buttonColor = 'warning';
  if (contentColor === '#31394C') buttonColor = 'info';

  const [buttonName, setButtonName] = React.useState(title);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const content = (
    <MUIButton
      color={buttonColor}
      variant={contentColor === 'transprent' ? 'outlined' : 'contained'}
      sx={{
        marginRight: { lg: '28px', xs: '13px' },
        width: { lg: '187px', xs: '135px' },
        height: { lg: '58px', xs: '40px' },
        backgroundColor: contentColor,
        borderRadius: '0px',
        borderColor: borderColor,
        borderWidth: { lg: '2px', xs: '1px' },
      }}
      className="black-text-wrapper"
    >
      <Typography
        component="p"
        sx={{
          fontSize: { lg: '17px', xs: '14px' },
          color: textColor,
          fontFamily: 'Lato-Bold',
          textTransform: 'none',
        }}
      >
        {buttonName}
      </Typography>
    </MUIButton>
  );

  return (
    <>
      <Box onClick={handleOpen}>{content}</Box>

      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <Box
          sx={{
            maxWidth: '600px',
            minWidth: { md: '600px', xs: '270px', sm: '400px' },
            height: '440px',
            px: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir-Bold',
              fontSize: '24px',
              textTransform: 'uppercase',
              mt: '30px',
            }}
          >
            Kontakt
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <ContactForm />
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(ContactButton);
